import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction004 from './Introduction004'
import Introduction005 from './Introduction005'
import Introduction006 from './Introduction006'
import Introduction007 from './Introduction007'
import Introduction008 from './Introduction008'
import Introduction009 from './Introduction009'
import Introduction010 from './Introduction010'
import Introduction011 from './Introduction011'
import Introduction012 from './Introduction012'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;

  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }

  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;

    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }

    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;

      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }

      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;

        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }

      &.txt-color-red {
        color: #ff6969;
      }
    }

    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;

      > div {
        width: 50px;
      }

      .link-p {
        width: calc(100% - 50px);

        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;

          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }

    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }

  ${BreakPoints.large} {
  }
`
type Props = {
  /** 広告の番号 */
  no: number
}

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0074 = (props: Props): JSX.Element => (
  <Wrapper>
    <ColumnH1 label="年俸制を採用した場合、賞与の有無や残業代はどうなる？メリットやデメリットについても解説！" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2024.09.04</p>
    <section>
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <p>
        近年、雇用形態だけでなく、給与形態も多様化しておりその一つに年俸制が挙げられます。年俸制とは、予め年間の給与額を定めておくことで、業種について制限はありません。年俸制の場合、度々問題になる論点として残業代は別途支払われるのかということです。今回は年俸制を採用した場合の給与の支払い方法、賞与の有無、退職したらどうなるか、メリットデメリット、残業代請求について解説します。
      </p>
      <img src="/images/column/details/c0074.jpg" alt="キンクラコラム画像74" />
      <div id="toc1">
        <h1>年俸制を採用した場合の給与の支払い方法</h1>
      </div>
      <p>
        「年俸制」というキーワードが一人歩きしている場合もありますが、
        <span>
          労働基準法上、給与は毎月1回以上を一定期日に支払わなければなりません。
        </span>
        これは労働基準法第24条に規定する「賃金支払い5原則」の中に含まれているもので、給与支給日の間隔が空きすぎることにより、生活が不安定となることを防ぐ意味で「毎月払い」の原則が設けられています。
      </p>

      <div id="toc2">
        <h1>年俸制を採用した場合の賞与の有無</h1>
      </div>
      <p>
        年俸制を採用した場合の問題となる論点に、賞与の有無が挙げられます。偏に年俸制と言っても、支払方法は複数あり、例えば単純に年俸額を12で除した額を毎月支払うというパターン、年俸額を14または16で除した額を毎月支払い、かつ、賞与月に残りの2か月分または4か月分を支払うというパターン、年俸とは別に業績に応じて賞与を支払うというパターンが考えられます。
        <br />
        <br />
        労働基準法上の賞与の考え方は臨時に支払われ、かつ、予め金額が定められていないものとされています。すなわち、定期的に支払われ、かつ、金額が確定している場合は労働基準法上の「賞与」にはあたらず、場合によっては割増賃金の計算基礎となる「賃金」に含まれることにもなりますので、慎重な対応が求められます。
        <br />
        <br />
        年俸とは別に賞与を支給する場合は、予め具体的な支給額が決まっていないことが多く、その場合は労働基準法上の賞与として扱われることとなります。すなわち、
        <span>支給額や支払いの有無までも企業側の裁量で決定する</span>
        ことができます。反対に、既に年俸に賞与を含めている契約の場合は、単純に経営状況が悪いからという理由のみでカットしてしまうと、直接的に年収が下がることとなりますので、労使合意なしにカットすることはできなくなります。
        <br />
        <br />
        年俸に賞与を組み込まず、かつ、就業規則や賃金規程等に「経営状況等によっては賞与を支給しないことがある」と規定しておくことで、賞与を強制的に支給しなければならなくなる事態を回避することができます。
      </p>

      {/** ここに9パターンの広告設定する */}
      {props.no === 0 && <Introduction004 />}
      {props.no === 1 && <Introduction005 />}
      {props.no === 2 && <Introduction006 />}
      {props.no === 3 && <Introduction007 />}
      {props.no === 4 && <Introduction008 />}
      {props.no === 5 && <Introduction009 />}
      {props.no === 6 && <Introduction010 />}
      {props.no === 7 && <Introduction011 />}
      {props.no === 8 && <Introduction012 />}

      <div id="toc3">
        <h1>年俸制を採用したものの中途退職した場合</h1>
      </div>
      <p>
        各月に支払うべき賃金は定められた支払期日に賃金請求権が発生します。そこで、
        <span>
          月の途中に退職となった場合は労働した分の賃金を日割り計算して支給するのが通常
        </span>
        です。退職によって労働契約が消滅し、退職日以降は労務の提供がありませんので、労働者の賃金請求権はないと考えられるためです。
        <br />
        <br />
        もちろん、年俸制を採用するということは会社側としても年間を通して労務提供を見越していたと思われ、やむを得ない事由があった場合を除き、
        <span>
          労働者側の一方的な契約解除となれば、債務不履行により損害が発生する
        </span>
        ことも考えられます。賞与についても在籍期間を按分して支給する等の対応方法が選択肢ですが、トラブルにならないように就業規則や賃金規程に定めておくことが望まれます。
      </p>

      <div id="toc4">
        <h1>年俸制を採用した場合の残業代</h1>
      </div>
      <p>
        年俸制を採用したとしても例外なく残業代が発生しないということはありませんが、まずは発生しないケースを確認しましょう。代表的なケースとしては管理監督者に対して年俸制を採用する場合です。名実ともに労働基準法上で定義する管理監督者にあたるのかという議論がありますが、その部分は割愛し、法律上、
        <span>
          管理監督者は労働時間、休憩、休日の規制がありませんので残業や休日労働という概念がありません。
        </span>
        一般的な職務に従事する労働者に対して職場内での名称として管理監督者と呼んでいたとしても、実質的に立場に見合った職務や給与が支払われていない場合、法的な争いとなれば管理監督者性が否認されるリスクがあります。また、深夜勤務を行った場合の割増賃金は支払いの義務がありますので、ご注意ください。
        <br />
        <br />
        次に
        <span>
          固定残業代を採用している場合でみなし時間が時間外労働時間数に達していない場合は「追加で」残業代を支給する必要はありません
        </span>
        。法律上、1日8時間、週40時間を超えた労働に対しては割増賃金の支払いが義務となります。年俸制で法定労働時間（1日8時間・週40時間）を超えた労働に対する賃金支払いを盛り込んだ労働契約の締結（例えばみなし時間が20時間の固定残業代込みの年俸制）をしていない場合は、法定労働時間を超えた労働に対する賃金（残業代）支払い義務がないと主張するには無理があります。年俸制であっても、法律上、1日8時間、週40時間を超えた労働に対しては割増賃金の支払いが義務となります。労働時間管理を厳密にし、残業が適切に把握できるようにしましょう。
        <br />
        <br />
        また、年俸に固定残業代を盛り込む契約を行っている場合でも、契約に含まれるみなし時間を超える労働が発生した場合は追加で残業代を支払わなければなりません。雇用契約書には何時間分の残業代が含まれているかを明記したうえで、労働時間の管理を行うことが求められます。
      </p>

      <div id="toc5">
        <h1>年俸制のメリット・デメリット</h1>
      </div>
      <p>
        年俸制は成果主義を採用する企業では既に導入されていることが多く、成果を上げることで翌年度の年俸額にも反映されるため、労働者に対してインセンティブを感じてもらいやすいというメリットがあります。他方、デメリットとしては、成果を上げることができなかった場合には翌年度の年俸額が減額される点や、企業側目線として、純然たる月給制と異なり、先行きが不透明であっても、賃金総額をより早期に決定する必要がある点です。
      </p>

      <div id="toc6">
        <h1>最後に</h1>
      </div>
      <p>
        実際に年俸制を導入するかについては、誤解が多い部分もあることから、同業他社の動向や経営状況等を総合的に勘案して決定していくことが望まれます。
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0074
